@use "colors";
@use "layout";
@use "typography";

.details {
  background: colors.$card_background;
  border-bottom: 1px solid #26394688;
  padding: 24px;
  width: 100%;

  &:first-child {
    border-radius: 12px 12px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 12px 12px;
    border-bottom: 0;
  }

  &[open] {
    color: colors.$col_dark_grey;

    summary:after {
      transform: rotate(90deg);
    }
  }

  summary {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background: none;
    list-style: none;
    margin: 0;
    padding: 0;

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }

    @include typography.heading5;
    align-items: center;
    color: colors.$col_dark_grey;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 28px;
      height: 28px;
      background: colors.$col_link_green url("/images/arrow_right.svg")
        no-repeat 3px 2px;
      background-size: 75%;
      border-radius: 100%;
    }
  }

  p {
    color: colors.$col_mid_grey;
    margin: 16px 0 0;
  }
}
