$col_light_grey: #faf6f3;
$col_mid_grey: #554e4e;
$col_dark_grey: #263946;
$col_green: #add021;
$col_link_green: #879d2d;

$base_background_color: #263946;
$base_font_color: #fff;
$card_background: #fff;
$card_title_color: #263946;

$accent_color: $col_link_green;
$card_color: $col_mid_grey;
