@use "colors";
@use "layout";
@use "typography";

.post {
  background-color: colors.$col_light_grey;
  color: colors.$col_dark_grey;

  &--inner {
    @include layout.page-column;
    @include layout.breakpoint("small-only") {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    @include layout.breakpoint("medium-only") {
      padding-top: 36px;
      padding-bottom: 36px;
    }
    @include layout.breakpoint("large-only") {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--meta {
    @include typography.meta;
    display: flex;
    gap: 0.3rem;
    margin-bottom: 48px;
  }

  &--return {
    @include typography.meta;
    margin: 48px 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: solid 2px colors.$accent_color;

    &:hover,
    &:focus {
      border-color: colors.$col_green;
    }
  }

  section {
    display: block; // TODO: Remove this when we remove the flex styles on the section tag
  }

  figure.highlight,
  pre.highlight {
    background-color: #eaeaea;
    margin: 0 0 24px;
    background: #eaeaea;
    border-radius: 0.5em;
    padding: 0.01em 0 0.01em 0.5em;
    overflow: scroll;

    @include typography.code;
  }
}
