@use "colors";
@use "layout";
@use "typography";

.post-listing {
  &,
  &--subtitle,
  &--list,
  &--list__with-columns {
    position: relative;
  }

  &--wallpaper {
    background-image: linear-gradient(
      colors.$col_light_grey 130px,
      colors.$base_background_color 130px
    );
    height: 240px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 100%;

    &::before {
      background: url("/images/wallpaper-left.svg") no-repeat;
      background-size: cover;
      content: "";
      height: 239px;
      left: 0;
      position: absolute;
      top: 30px;
      width: 181px;

      @media screen and (max-width: 1150px) {
        left: calc(50% - 575px);
      }
    }

    &::after {
      background: url("/images/wallpaper-right.svg") no-repeat;
      background-size: cover;
      content: "";
      height: 235px;
      right: 0;
      position: absolute;
      top: 30px;
      width: 156px;

      @media screen and (max-width: 1150px) {
        right: calc(50% - 577px);
      }
    }
  }
}

.post-listing {
  background-color: colors.$base_background_color;
  color: colors.$col_light_grey;

  &--subtitle {
    @include layout.page-column;
    @include typography.heading4;
    margin-bottom: 0;
    margin-top: 0;
    text-wrap: balance;
    text-align: center;
    width: 100%;

    @include layout.breakpoint("small-only") {
      padding-top: 34px;
      padding-bottom: 30px;
    }
    @include layout.breakpoint("medium-and-large") {
      padding-top: 52px;
      padding-bottom: 45px;
    }

    &__first {
      color: colors.$col_dark_grey;
      padding-top: 0;
    }
  }

  &--list,
  &--list__with-columns {
    display: flex;
    gap: 32px 24px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 auto;

    &:last-child {
      @include layout.breakpoint("small-only") {
        padding-bottom: 90px;
      }
      @include layout.breakpoint("medium-and-large") {
        padding-bottom: 120px;
      }
    }
  }

  &--list {
    @include layout.page-column;
  }

  &--list__with-columns {
    @include layout.page-section;
  }

  &--list-item {
    width: 100%;

    .post-listing--list__with-columns & {
      @include layout.breakpoint("medium-and-large") {
        width: calc((100% - 24px) / 2);
      }
    }
  }
}

.link-card {
  background: colors.$card_background;
  border: none;
  border-radius: 12px;
  color: colors.$card_color;
  display: block;
  height: 100%;
  padding: 24px;
  text-decoration: none;

  *:last-child {
    margin-bottom: 0;
  }
}

.link-card--title {
  @include typography.heading5;
  color: colors.$card_title_color;
  display: inline-block;
  margin-bottom: 22px;
  text-wrap: balance;

  a &,
  & a {
    border-bottom: 2px solid colors.$col_green;
  }
}

.link-card--summary {
  text-wrap: balance;
}
