@use "colors";
@use "layout";
@use "typography";
@use "util-classes";
@use "components/about";
@use "components/introduction";
@use "components/details";
@use "components/footer";
@use "components/masthead";
@use "components/post-listing";
@use "components/post";
@use "components/services-cards";
@use "components/services-form";
@use "components/shifted-column";
@use "components/video-card";

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  background-color: colors.$base_background_color;
  color: colors.$base_font_color;
  margin: 0;
  @include typography.body;
}

h1,
h2,
h3,
h4,
h5 {
  text-wrap: balance;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-top: 0;
  margin-bottom: 24px;
}

h1,
h2,
h3,
h4,
h5 {
  p + &,
  ul + &,
  li + & {
    margin-top: 48px;
  }
}

h1,
.h1 {
  @include typography.heading1;
}

h2,
.h2 {
  @include typography.heading2;
}

h3,
.h3 {
  @include typography.heading3;
}

h4,
.h4 {
  @include typography.heading4;
}

h5,
.h5 {
  @include typography.heading5;
}

h6,
.h6 {
  @include typography.heading6;
}

sub {
  @include typography.meta;
}

blockquote {
  border-left: 3px solid colors.$accent_color;
  padding: 24px 40px 0.1px;
  margin-left: 0;
}

pre {
  max-width: calc(100vw - 32px);
  overflow: scroll;
}

code {
  @include typography.code;
}

a {
  article &,
  main & {
    color: inherit;
    text-decoration: none;
    border-bottom: solid 2px colors.$accent_color;

    &:hover,
    &:focus {
      border-color: colors.$col_green;
    }
  }
}

.branded-main {
  background-color: colors.$col_dark_grey;
  padding-bottom: 80px;
}
