@use "colors";
@use "layout";
@use "typography";

.masthead {
  background-color: colors.$col_light_grey;

  &--max-width {
    @include layout.page-section;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include layout.breakpoint("small-only") {
      gap: 20px;
      padding-top: 24px;
      padding-bottom: 16px;
    }
    @include layout.breakpoint("medium-and-large") {
      gap: 32px;
    }
    @include layout.breakpoint("medium-only") {
      padding-top: 36px;
      padding-bottom: 30px;
    }
    @include layout.breakpoint("large-only") {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &--h1 {
    margin: 0;
  }

  .branding {
    display: flex;
    justify-content: center;
    align-items: baseline;
    position: relative;

    .logo,
    .tagline {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .logo {
      background-image: url("/images/2__More Logo_Dark.svg");
      background-size: contain;
    }
    .tagline {
      position: relative;
      background-image: url("/images/Refactoring to maintainability_dark.svg");
    }
    @include layout.breakpoint("small-only") {
      gap: 8px;
      top: -2px;

      .logo {
        height: 26.66px;
        width: 112px;
      }
      .tagline {
        height: 24px;
        width: 81.3px;
        top: 2px;
      }
    }
    @include layout.breakpoint("medium-and-large") {
      gap: 12px;
      top: -3px;

      .logo {
        height: 40px;
        width: 168px;
      }
      .tagline {
        height: 36px;
        width: 122px;
        top: 3px;
      }
    }
  }

  .navigation {
    display: flex;

    @include layout.breakpoint("small-only") {
      gap: 16px;
    }
    @include layout.breakpoint("medium-and-large") {
      gap: 32px;
    }
  }

  .menu--link {
    @include typography.heading5;
    text-decoration: none;
  }

  .feed-link {
    display: flex;
    align-items: center;
    border: 1px solid colors.$accent_color;
    text-decoration: none;

    &--icon {
      width: auto;
    }
    &--text {
      color: colors.$accent_color;
      font-family: "bornaregular";
    }

    @include layout.breakpoint("small-only") {
      gap: 5px;
      padding: 5px 11px;
      border-radius: 27px;

      &--icon {
        height: 16px;
      }
      &--text {
        font-size: 12px;
        line-height: 16px;
      }
    }
    @include layout.breakpoint("medium-and-large") {
      gap: 8px;
      padding: 8px 20px;
      border-radius: 40px;

      &--icon {
        height: 20px;
      }
      &--text {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  @include layout.breakpoint("small-and-medium") {
    .menu__large-screen {
      display: none;
    }

    .menu--toggle {
      appearance: none;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border: 0;
      cursor: pointer;
      display: block;

      @include layout.breakpoint("small-only") {
        width: 32px;
        height: 36.6px;
      }
      @include layout.breakpoint("medium-only") {
        width: 48px;
        height: 55px;
      }
    }

    .menu--link {
      color: colors.$col_light_grey;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      border-top-style: solid;
      border-top-color: colors.$col_light_grey;

      @include layout.breakpoint("small-only") {
        border-bottom-width: 2px;
        border-top-width: 1px;
        padding: 24px 0;
        font-size: 32px;
        line-height: 36px;
      }
      @include layout.breakpoint("medium-only") {
        border-bottom-width: 4px;
        border-top-width: 1px;
        padding: 32px 0;
        font-size: 48px;
        line-height: 55px;
      }

      &.current {
        color: colors.$accent_color;
        border-bottom-color: colors.$accent_color;
      }

      &:first-of-type {
        border-top-color: transparent;
      }
    }

    .menu--link.current + .menu--link {
      border-top-color: transparent;
    }

    &.open {
      background-color: colors.$col_dark_grey;
      min-height: 100vh;

      .logo {
        background-image: url("/images/2__More Logo_Light.svg");
      }

      .tagline {
        background-image: url("/images/Refactoring\ to\ maintainability_light.svg");
      }

      .feed-link {
        display: none;
      }

      .menu--toggle {
        background-image: url("/images/bars-solid.svg");
      }
      .menu__small-screen {
        display: flex;
        flex-direction: column;

        @include layout.breakpoint("small-only") {
          padding: 38px 16px;
        }
        @include layout.breakpoint("medium-only") {
          padding: 57px 24px;
        }
      }
    }
    &:not(.open) {
      .menu--toggle {
        background-image: url("/images/burger-bar.svg");
      }

      .menu__small-screen {
        display: none;
      }
    }
  }

  @include layout.breakpoint("large-only") {
    .menu--toggle {
      display: none;
    }

    .menu__small-screen {
      display: none;
    }

    .menu__large-screen {
      display: flex;
      gap: 24px;
      align-items: center;

      .menu--link {
        border-bottom: 2px solid transparent;
        color: colors.$col_dark_grey;
        padding-top: 2px;
        @include typography.heading5;

        &.current {
          border-bottom-color: colors.$accent_color;
        }
      }
    }
  }
}
