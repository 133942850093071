@use "colors";
@use "layout";
@use "typography";

.shifted-column {
  padding: 0 0 32px;
  position: relative;

  &--wallpaper {
    background-image: linear-gradient(
      colors.$col_light_grey 100px,
      colors.$col_dark_grey 100px
    );
    height: 320px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;

    &::before {
      background: url("/images/wallpaper-left.svg") no-repeat;
      background-size: cover;
      content: "";
      height: 239px;
      left: 0;
      position: absolute;
      top: 0;
      width: 181px;

      @media screen and (max-width: 1150px) {
        left: calc(50% - 575px);
      }
    }

    &::after {
      background: url("/images/wallpaper-right.svg") no-repeat;
      background-size: cover;
      content: "";
      height: 235px;
      right: 0;
      position: absolute;
      top: 0;
      width: 156px;

      @media screen and (max-width: 1150px) {
        right: calc(50% - 577px);
      }
    }
  }

  &--title {
    @include layout.page-column;
    @include typography.heading4;
    color: colors.$col_dark_grey;
    position: relative;

    @include layout.breakpoint("small-only") {
      margin-bottom: 12px;
    }
    @include layout.breakpoint("medium-and-large") {
      margin-bottom: 24px;
    }
  }

  &--subtitle {
    @include layout.page-column;
    @include typography.heading4;

    @include layout.breakpoint("small-only") {
      margin-top: 24px;
      margin-bottom: 12px;
    }
    @include layout.breakpoint("medium-and-large") {
      margin-top: 48px;
      margin-bottom: 24px;
    }
  }
}

.shifted-column--body {
  position: relative;
}
