@use "colors";
@use "layout";

@font-face {
  font-family: "bariol_serifregular";
  src: url("/fonts/bariol_serif_regular-webfont.eot");
  src: url("/fonts/bariol_serif_regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/bariol_serif_regular-webfont.woff2") format("woff2"),
    url("/fonts/bariol_serif_regular-webfont.woff") format("woff"),
    url("/fonts/bariol_serif_regular-webfont.ttf") format("truetype"),
    url("/fonts/bariol_serif_regular-webfont.svg#bariol_serifregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bornaregular";
  src: url("/fonts/borna-regular-webfont.eot");
  src: url("/fonts/borna-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/borna-regular-webfont.woff2") format("woff2"),
    url("/fonts/borna-regular-webfont.woff") format("woff"),
    url("/fonts/borna-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

$main_title_font: "bornaregular";

@mixin heading1 {
  font-family: "bornaregular";
  font-size: 48px;
  font-weight: 500;
  line-height: 55px;

  @include layout.breakpoint("small-only") {
    font-size: 32px;
    line-height: 38px;
  }
}

@mixin heading2 {
  font-family: "bornaregular";
  font-size: 40px;
  font-weight: 500;
  line-height: 47px;

  @include layout.breakpoint("small-only") {
    font-size: 28px;
    line-height: 34px;
  }
}

@mixin heading3 {
  font-family: "bornaregular";
  font-size: 36px;
  font-weight: 500;
  line-height: 43px;

  @include layout.breakpoint("small-only") {
    font-size: 24px;
    line-height: 29px;
  }
}

@mixin heading4 {
  font-family: "bornaregular";
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;

  @include layout.breakpoint("small-only") {
    font-size: 22px;
    line-height: 26px;
  }
}

@mixin heading5 {
  font-family: "bornaregular";
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;

  @include layout.breakpoint("small-only") {
    font-size: 16px;
    line-height: 21px;
  }
}

@mixin heading6 {
  font-family: "bornaregular";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;

  @include layout.breakpoint("small-only") {
    font-size: 13px;
    line-height: 18px;
  }
}

@mixin body {
  font-family: "bariol_serifregular";
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;

  @include layout.breakpoint("small-only") {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin meta {
  font-family: "bariol_serifregular";
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;

  @include layout.breakpoint("small-only") {
    font-size: 11px;
    line-height: 15px;
  }
}

@mixin code {
  font-family: Fira Code;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;

  @include layout.breakpoint("small-only") {
    font-size: 11px;
    line-height: 15px;
  }
}

@mixin big-green-button {
  appearance: none;
  background-color: colors.$accent_color;
  color: colors.$base_font_color;
  cursor: pointer;
  border: 0 none;
  border-radius: 48px;
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  @include heading5;

  &:hover {
    background-color: colors.$col_green;
  }
}
