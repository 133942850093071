@use "colors";
@use "layout";
@use "typography";

.services--cards {
  @include layout.page-column;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.services-card {
  background-color: white;
  border-radius: 12px;
  color: colors.$col_mid_grey;
  padding: 24px;

  &--title {
    @include typography.heading5;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
