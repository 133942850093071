@use "colors";
@use "layout";
@use "typography";

.page-footer {
  background-color: #1c272e;

  &--inner {
    @include layout.page-section;
    display: grid;

    @include layout.breakpoint("small-only") {
      padding-top: 24px;
      padding-bottom: 16px;
      grid-template-columns: 1fr;
      gap: 32px;
    }
    @include layout.breakpoint("medium-and-large") {
      grid-template-columns: 1fr 1fr;
    }
    @include layout.breakpoint("medium-only") {
      gap: 64px;
      padding-top: 36px;
      padding-bottom: 30px;
    }
    @include layout.breakpoint("large-only") {
      gap: 126px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  h5,
  p,
  a {
    color: #fff;
    margin-bottom: 12px;
  }

  h5 {
    text-decoration: none;
  }

  p,
  small {
    @include typography.body;
  }

  a {
    text-decoration: none;
    border-bottom: 1px solid colors.$accent_color;

    &:hover {
      border-bottom-color: colors.$col_green;
    }
  }
}
