@use "colors";
@use "layout";
@use "typography";

.about--details-collection {
  @include layout.page-column;
  display: flex;
  flex-direction: column;
}

.about-two-up {
  @include layout.page-column;
  display: grid;
  gap: 32px;

  @include layout.breakpoint("large-only") {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  &--card {
    background-color: white;
    border-radius: 12px;
    color: colors.$col_mid_grey;
    padding: 24px;
  }
  &--title {
    @include typography.heading5;
  }
}

.about-big-card {
  @include layout.page-column;

  &--inner {
    background-color: white;
    border-radius: 12px;
    color: colors.$col_mid_grey;
    padding: 24px;
    margin-top: 32px;
  }
  &--double-list {
    @include layout.breakpoint("medium-and-large") {
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.about-link-list {
  line-height: 32px;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
