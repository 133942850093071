@use "colors";
@use "layout";
@use "typography";

.services-form {
  @include layout.page-column;
  padding-top: 12px;

  &--title {
    @include typography.heading4;

    @include layout.breakpoint("small-only") {
      margin-bottom: 15px;
    }
    @include layout.breakpoint("medium-and-large") {
      margin-bottom: 20px;
    }
  }
  &--form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &--two-up {
    display: grid;
    gap: 20px;

    @include layout.breakpoint("medium-and-large") {
      grid-template-columns: 1fr 1fr;
    }
  }
  &--label-input-combo,
  &--label-textbox-combo {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  &--label {
    @include typography.heading6;
  }
  &--text-input,
  &--textbox {
    @include typography.body;
    border-radius: 8px;
    padding: 12px;
    border: 0 none;
    appearance: none;

    &::placeholder {
      color: #a3aaae;
    }
  }
  &--submit {
    @include typography.big-green-button;
    align-self: flex-end;
  }

  &--thanks,
  &--error {
    @include layout.page-column;
  }
}
