@mixin breakpoint($point) {
  @if $point == "small-only" {
    @media (max-width: 720px) {
      @content;
    }
  }
  @if $point == "small-and-medium" {
    @media (max-width: 1099px) {
      @content;
    }
  }
  @if $point == "medium-only" {
    @media (min-width: 721px) and (max-width: 1099px) {
      @content;
    }
  }
  @if $point == "medium-and-large" {
    @media (min-width: 721px) {
      @content;
    }
  }
  @if $point == "large-only" {
    @media (min-width: 1100px) {
      @content;
    }
  }
}

@mixin page-section {
  @include breakpoint("small-only") {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include breakpoint("medium-only") {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include breakpoint("large-only") {
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@mixin page-column {
  @include breakpoint("small-only") {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include breakpoint("medium-only") {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include breakpoint("large-only") {
    margin: 0 auto;
    max-width: 882px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
