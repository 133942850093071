@use "colors";
@use "layout";
@use "typography";

.video-card {
  background: #0e619c url("/images/video-branding.svg") no-repeat 48px
    calc(100% - 48px);
  border: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 48px;
  position: relative;
  text-decoration: none;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  margin: 0 auto 40px;
  max-width: 802px;
  width: 100%;

  &:before {
    background-color: colors.$col_light_grey;
    background-image: url("/images/video-play-icon.svg");
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: 35px center;
    border-radius: 100%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 125px;
    height: 125px;
  }

  &:after {
    background-image: url("/images/video-decoration.svg");
    background-size: contain;
    background-repeat: no-repeat;
    content: "";
    display: block;
    position: absolute;
    top: -20px;
    right: -20px;
    width: 247px;
    height: 247px;
  }

  *:last-child {
    margin-bottom: 0;
  }

  @include layout.breakpoint("small-only") {
    padding: 24px;
    background-size: 50px;
    background-position: 24px calc(100% - 24px);
    margin-bottom: 0;

    &:before {
      background-size: 55px;
      background-position: 30px center;
      width: 100px;
      height: 100px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 16px;
    background-size: 40px;
    background-position: 16px calc(100% - 16px);

    &:before {
      background-size: 30px;
      background-position: 16px center;
      width: 55px;
      height: 55px;
    }
  }
}

.video-card--title {
  @include typography.heading2;
  color: colors.$col_light_grey;
  display: inline-block;
  text-wrap: balance;
  max-width: 38%;

  @media screen and (max-width: 480px) {
    @include typography.heading4;
    align-self: flex-start;
  }
}
