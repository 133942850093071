@use "colors";
@use "layout";
@use "typography";

.introduction {
  background-color: colors.$col_light_grey;
  color: colors.$col_dark_grey;

  &--title,
  &--body:not(:last-child) {
    text-wrap: balance;
  }

  &--inner {
    @include layout.page-column;
    padding-top: 40px;
    padding-bottom: 60px;
    display: flex;
    gap: 24px;

    @include layout.breakpoint("small-only") {
      flex-direction: column-reverse;
      padding: 26px 16px 52px;
      gap: 16px;
    }
  }

  &--image {
    align-self: flex-start;
    position: relative;

    @include layout.breakpoint("medium-and-large") {
      top: 1em;
    }
  }

  &--cta {
    @include typography.big-green-button;
  }
}
